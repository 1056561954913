import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\components\\Ctasection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\components\\Headercontent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\components\\Headerform.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\components\\Studentreview.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\components\\UniversitySec.js");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\node_modules\\react-bootstrap\\esm\\Col.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\node_modules\\react-bootstrap\\esm\\Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\github\\assignmentwritingserviceae\\node_modules\\react-bootstrap\\esm\\Row.js");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\countershape.webp");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\customerCare.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\featureIcon.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\graduationicon.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\headerGirl.webp");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\highQuality.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\plagiaramFree.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\qualifiedWriters.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\timelyDelivery.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\unlimitedRevision.png");
;
import(/* webpackMode: "eager" */ "D:\\github\\assignmentwritingserviceae\\public\\assets\\images\\whychooseimg.webp");
