"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Image from "next/image";
import abudhabiuni from "../public/assets/images/Abu-Dhabi-University.png";
import alainuni from "../public/assets/images/Al-Ain-University.png";
import americanunidubai from "../public/assets/images/American-University-Dubai.png";
import heriotwattuni from "../public/assets/images/Heriot-Watt-University.png";
import middlesexuni from "../public/assets/images/Middlesex-University-Dubai.png";
import nyuabudhabi from "../public/assets/images/NYU-ABU-DHABI.png";
import uaeuni from "../public/assets/images/United-Arab-Emirates-University.png";
import uniwollongong from "../public/assets/images/University-of-Wollongong-in-DUbai.png";
import zayeduni from "../public/assets/images/Zayed-University.png";
import { Col, Container, Row } from "react-bootstrap";

const UniversitySec = () => {
  return (
    <>
      <div className="caterStudent">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <h4>We Cater Students From Top Universities In UAE</h4>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={abudhabiuni}
                      style={{ height: "auto" }}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image src={alainuni} alt="" style={{ height: "auto" }} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={americanunidubai}
                      alt=""
                      style={{ height: "auto" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={heriotwattuni}
                      alt=""
                      style={{ height: "auto" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={middlesexuni}
                      alt=""
                      style={{ height: "auto" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={nyuabudhabi}
                      alt=""
                      style={{ height: "auto" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image src={uaeuni} alt="" style={{ height: "auto" }} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image
                      src={uniwollongong}
                      alt=""
                      style={{ height: "auto" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="UniLogo">
                    <Image src={zayeduni} alt="" style={{ height: "auto" }} />
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UniversitySec;
