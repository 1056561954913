"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import emailIcon from "../public/assets/images/emailIcon.png";
import fiveStar from "../public/assets/images/5-star.png";
import truspilot from "../public/assets/images/truspilot.png";
import bark from "../public/assets/images/bark.png";
import sitejabbar from "../public/assets/images/sitejabbar.png";
import Image from "next/image";
import Link from "next/link";
import { Col, Container, Row } from "react-bootstrap";
const Studentreview = () => {
  return (
    <>
      <div className="studentTest">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12} sm={12}>
              <div className="testionial_sec">
                <b>
                  Inspiring UAE's premier service to help you resolve your
                  writing queries.
                </b>

                <h5>Get An Insight Into The Reviews Of Our Customer</h5>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8} md={8} xs={12} sm={12}>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      I was very trouble in finishing my research homework
                      because I am not English person and the work was for
                      English course. Assignment writing service help me and I
                      got very good grade….thank you very much
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Amelia</span>

                        <p>Diploma Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      I wanted to make my sociology assignment very good and
                      that is why asked for proofreading and editing from this
                      writing company. They did very nice job and helped fix all
                      the errors. I was able to get a nice grade.
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Abdullah Ali</span>

                        <p>College Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      I highly recommend assignmentwritingservice.ae. O was
                      running short on time and ordered a philosophy assignment
                      with 12 hours deadline only. They did the job in excellent
                      quality. Thank you so much!
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Muneeb Butt</span>

                        <p>High School Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      I have tried so many assignment help websites but this one
                      is truly the best. Thank you to the accounting writer who
                      did my assignment for me I love it my teacher love it too
                      and gave me the highest grade for it.
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Hiba Shah</span>

                        <p>High School Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      I am highly satisfied with the services of this company.
                      They deliver what they promise and also caters to all the
                      customers in a very friendly manner. Highly recommended to
                      all for quality work and best prices.
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Aida Rafeeq</span>

                        <p>University Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="StuWrap">
                    <div className="rating">
                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>

                      <i className="fas fa-star"></i>
                    </div>

                    <p>
                      The best thing about assignment writing service UAE is
                      their friendly customer support team and unlimited
                      revision policy. I was very satisfied with the revisions
                      and their staff behavior. Rating 5/5 best service ever.
                    </p>

                    <div className="footerStd">
                      <div className="Stdicon">
                        <Image
                          src={emailIcon}
                          style={{ height: "auto" }}
                          alt=""
                        />
                      </div>

                      <div className="infoStd">
                        <span>Aadil Mustafa</span>

                        <p>College Student</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>

            <Col lg={4} md={4} xs={12} sm={12}>
              <div className="Reviewx">
                <div className="FiveStarBox">
                  <Link href="#">
                    <Image
                      src={fiveStar}
                      alt=""
                      style={{ height: "auto" }}
                      className="img-fluid"
                    />
                  </Link>
                </div>

                <div className="RatingReviews">
                  <div className="ReviewItems">
                    <Link href="#">
                      <Image
                        src={truspilot}
                        style={{ height: "auto" }}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="ReviewItems">
                    <Link href="#">
                      <Image src={bark} style={{ height: "auto" }} alt="" />
                    </Link>
                  </div>

                  <div className="ReviewItems">
                    <Link
                      href="#"
                      style={{ marginTop: "7px", display: "block" }}
                    >
                      <Image
                        src={sitejabbar}
                        style={{ height: "auto" }}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Studentreview;
