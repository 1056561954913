"use client";
import { useRouter } from "next/navigation";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { API_URL } from "@/config";

const Headerform = () => {
  const { push } = useRouter();

  const [document, setDocument] = useState("");
  const [papertype, setPapertype] = useState("");
  const [pages, setPages] = useState("");
  const [academic, setAcademic] = useState("");
  const [deadline, setDeadline] = useState("");
  const [Price, setPrice] = useState("0");

  const orderSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();

    localStorage.setItem("document", document);
    localStorage.setItem("papertype", papertype);
    localStorage.setItem("pages", pages);
    localStorage.setItem("academic", academic);
    localStorage.setItem("deadline", deadline);
    localStorage.setItem("Amount", Price);
    push("/order");
  };

  useEffect(() => {
    getData(pages, academic, deadline);
  }, [pages, academic, deadline]);

  const getData = async (pages, academic, deadline) => {
    if (pages == "") {
    } else {
      const query = await fetch(
        `${API_URL}priceChange.php?pages=${pages}&academic=${academic}&deadline=${deadline}`
      );
      const response = await query.json();
      setPrice(response);
    }
  };
  return (
    <>
      <form onSubmit={orderSubmit} method="post">
        <div className="orderForm">
          <div className="formdetails">
            <p>Place An Order</p>

            <div className="form-group">
              <select
                name="document"
                id="document"
                className="form-control"
                onChange={(e) => setDocument(e.target.value)}
                value={document}
              >
                <option value="">Select Paper Type</option>
                <option value="Essay">Essay</option>
                <option value="Coursework">Coursework</option>
                <option value="Assignment">Assignment</option>
                <option value="Dissertation">Dissertation</option>
                <option value="Thesis">Thesis</option>
                <option value="Research Proposal">Research Proposal</option>
                <option value="Literature Review">Literature Review</option>
                <option value="Case Study">Case Study</option>
                <option value="Report">Report</option>
                <option value="Editing and Proofreading">
                  Editing and Proofreading
                </option>
                <option value="PowerPoint Slides">PowerPoint Slides</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div className="form-group">
              <select
                name="pages"
                id="pages"
                className="form-control"
                required
                onChange={(e) => setPages(e.target.value)}
                value={pages}
              >
                <option value="">Please Select Pages</option>
                {Array(200)
                  .fill(1)
                  .map((el, i) => {
                    let ss = i + 1;
                    return (
                      <option key={ss} value={i + 1}>
                        {i + 1} Page(s) / {250 * ss} Words
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-group">
              <select
                id="academic"
                className="form-control"
                name="academic"
                required
                onChange={(e) => setAcademic(e.target.value)}
                value={academic}
              >
                <option value="">Select Academic Type</option>
                <option value="high-school">High School</option>
                <option value="College">College</option>
                <option value="Undergraduate">Undergraduate</option>
                <option value="Master">Master's</option>
                <option value="PhD">Ph.D</option>
                <option value="Admission">Admission</option>
              </select>
            </div>

            <div className="form-group">
              <select
                name="deadline"
                id="deadline"
                className="form-control"
                required
                onChange={(e) => setDeadline(e.target.value)}
                value={deadline}
              >
                <option value="">Select Deadline</option>
                <option value="20">
                  20 Days / {moment().add(20, "d").format("D MMM, YYYY")}
                </option>
                <option value="15">
                  15 Days / {moment().add(15, "d").format("D MMM, YYYY")}
                </option>
                <option value="10">
                  10 Days / {moment().add(10, "d").format("D MMM, YYYY")}
                </option>
                <option value="7">
                  7 Days / {moment().add(7, "d").format("D MMM, YYYY")}
                </option>
                <option value="6">
                  6 Days / {moment().add(6, "d").format("D MMM, YYYY")}
                </option>
                <option value="5">
                  5 Days / {moment().add(5, "d").format("D MMM, YYYY")}
                </option>
                <option value="4">
                  4 Days / {moment().add(4, "d").format("D MMM, YYYY")}
                </option>
                <option value="3">
                  3 Days / {moment().add(3, "d").format("D MMM, YYYY")}
                </option>
                <option value="2">
                  2 Days / {moment().add(2, "d").format("D MMM, YYYY")}
                </option>

                <option value="24h">
                  24 Hours / {moment().add(24, "h").format("D MMM, YYYY")}
                </option>
                <option value="12h">
                  12 Hours / {moment().add(12, "h").format("D MMM, YYYY")}
                </option>
                <option value="6h">
                  6 Hours / {moment().add(6, "h").format("D MMM, YYYY")}
                </option>
              </select>
            </div>

            <div className="form-group">
              <button
                type="submit"
                name="order_submit"
                className="btn btn-primary"
              >
                Place Order Now
              </button>
            </div>
          </div>

          <div className="amountCounter">
            <h6>AED</h6>
            <p id="total_amount_cal">{Price}</p>
          </div>
        </div>
      </form>
    </>
  );
};

export default Headerform;
