"use client";
import React from "react";
import callIcon from "../public/assets/images/callIcon.png";
import chatIcon from "../public/assets/images/chatIcon.png";
import whatsappIcons from "../public/assets/images/Mainwhatssapp.webp";
import emailIcon from "../public/assets/images/emailIcon.png";

import Image from "next/image";
import Link from "next/link";
import { emaillink, handleOpenChat, whatsapplink } from "@/config";
import { Col, Container, Row } from "react-bootstrap";

const Ctasection = () => {
  return (
    <>
      <div className="calltoacton">
        <Container>
          <Row>
            <Col lg={3} md={3}>
              <div className="CallWrap">
                <div className="CallIcon">
                  <Image
                    src={callIcon}
                    style={{ height: "auto" }}
                    alt="callIcon"
                  />
                </div>

                <div className="callinfo">
                  <p className="m-0">Call Now</p>
                  <Link href={whatsapplink}>+971 42473777</Link>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3}>
              <div className="CallWrap">
                <div className="CallIcon">
                  <Image
                    src={chatIcon}
                    style={{ height: "auto" }}
                    alt="chatIcon"
                  />
                </div>

                <div className="callinfo">
                  <p className="m-0">Need Help ?</p>

                  <Link href="#" onClick={handleOpenChat}>
                    Start Chat
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3}>
              <div className="CallWrap">
                <div className="CallIcon">
                  <Image
                    src={whatsappIcons}
                    style={{ height: "auto" }}
                    alt="whatsappIcons"
                  />
                </div>

                <div className="callinfo">
                  <p className="m-0">Whatsapp</p>

                  <Link href={whatsapplink}>+971 42473777</Link>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3}>
              <div className="CallWrap">
                <div className="CallIcon">
                  <Image
                    src={emailIcon}
                    style={{ height: "auto" }}
                    alt="emailIcon"
                  />
                </div>

                <div className="callinfo">
                  <p className="m-0">Email Us</p>

                  <Link href={emaillink}>info@assignmentwritingserive.ae</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Ctasection;
