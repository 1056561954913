"use client";
import Image from "next/image";
import React from "react";
import checked from "../public/assets/images/checked.svg";
import whatsapp from "../public/assets/images/whatsapp.png";
import arrowline from "../public/assets/images/arrowline.png";
import Link from "next/link";
import { handleOpenChat, whatsapplink } from "@/config";

const Headercontent = () => {
  return (
    <>
      <div className="HeaderContent">
        <h1>Top Assignment Writing Services in UAE</h1>

        <p className="pt-3 pb-3">
          Provided By Experienced And Expert Assignment Writers
        </p>

        <ul className="listitem ps-0">
          <li>
            <Image src={checked} alt="checked" style={{ height: "auto" }} />{" "}
            Select the assignment type
          </li>

          <li>
            <Image src={checked} alt="checked" style={{ height: "auto" }} />{" "}
            Select the writer
          </li>

          <li>
            <Image src={checked} alt="checked" style={{ height: "auto" }} /> Get
            your assignment help
          </li>
        </ul>

        <ul className="footerBtn">
          <li>
            <Link href="/order" className="ordernow">
              Order Now
            </Link>
          </li>

          <li>
            <Link href="#" onClick={handleOpenChat} className="livechat">
              Live Chat
            </Link>
          </li>

          <li>
            <Link href={whatsapplink} className="whatsapp">
              <Image src={whatsapp} alt="Whatsapp" style={{ height: "auto" }} />{" "}
              +971 42473777
            </Link>
            <Image
              src={arrowline}
              style={{ height: "auto" }}
              className="arrowFound d-block d-lg-none d-md-none d-sm-none"
              alt="arrowline"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Headercontent;
